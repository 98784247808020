import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
  makeStyles
} from '@material-ui/core';
import NavItem from './NavItem';
import {
  Home as HomeIcon,
  FileText as FileTextIcon,
  Image as ImageIcon,
  Users as UsersIcon,
  Archive as ArchiveIcon,
  CheckSquare as CheckSquareIcon,
  CreditCard as CreditCardIcon,
  Tag as TagIcon,
  File as FileIcon,
  Briefcase as BriefcaseIcon,
} from 'react-feather';

const sections = [
  {
    subheader: 'Menu',
    items: [
      {
        title: 'รายงานการรับชำระเงิน',
        href: '/promrub/receive-payment',
        icon: CreditCardIcon,
      },
      {
        title: 'รายงานภาษีขาย',
        href: '/promrub/tax-report',
        icon: CreditCardIcon,
      },
    ]
  },
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        open={Boolean(open)}
        title={item.title}
        img={item.img}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        link={item.link}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        img={item.img}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    background: theme.palette.white,
    borderRight: '1px solid #D4DDE3',
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className={classes.root}
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={0} pt={4}>
          {sections.map((section) => (
            <List
              key={section.subheader}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
