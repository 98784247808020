import { combineReducers } from '@reduxjs/toolkit';
import { reducer as taxReportReducer } from '../slices/taxReport';
import { reducer as receivePaymentReducer } from '../slices/receivePayment';

const rootReducer = combineReducers({
    taxReport: taxReportReducer,
    receivePayment: receivePaymentReducer
});

export default rootReducer;
