import React from 'react';
import {
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  image: {
    maxHeight: 40,
    maxWidth: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 40,
      maxWidth: 'auto',
    }
  },
}));

const Logo = (props) => {
  const classes = useStyles();
  return (
    <img
      alt="Logo"
      src="/static/images/logo.png"
      {...props}
      className={classes.image}
    />
  );
}

export default Logo;
