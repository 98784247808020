import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import API from '../utils/api';
import encodeParams from '../utils/queryParam'

const initialState = {
  isLoading: false,
  data: {
    currentPage: 0,
    hasNext: false,
    hasPrevious: false,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    items: []
  },
};

const slice = createSlice({
  name: 'receivePayment',
  initialState,
  reducers: {
    getList(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    setPending(state, action) {
      state.isLoading = true;
    },
  }
});

export const reducer = slice.reducer;

export const getList = (id, parameter) => async (dispatch) => {
    dispatch(slice.actions.setPending());
    id = '0107565000158.00000'

    const url = `${API.URL_RECEIVE_PAYMENT}/${id}?${encodeParams(parameter)}`
    const response = await axios.get(url);
    dispatch(slice.actions.getList(response.data.data));
};

export const getListByDate = (id, parameter) => async (dispatch) => {
    dispatch(slice.actions.setPending());
    id = '0107565000158.00000'
    const url = `${API.URL_RECEIVE_PAYMENT}/${id}/by_date?${encodeParams(parameter)}`
    const response = await axios.get(url);
    dispatch(slice.actions.getList(response.data.data));
};

export const getListExport = async (id, isDate, parameter) => {
  try {
    id = '0107565000158.00000'
    const url = `${API.URL_RECEIVE_PAYMENT}/${id}${isDate ? '/by_date' : ''}?${encodeParams(parameter)}`
    const res = await axios.get(url);
    return res.data.data;
  } catch (err) {
    console.log(JSON.stringify(err))
    return { isError: true };
  }
};

export default slice;
