export default {
    root: {},
    select: {
        fontSize: 16,
    },
    selectMenu: {
        // minHeight: '1.5rem',
        padding: '9.5px 16px',
    }
  };
  